@import "./styles/_styles.scss";
.scrollIndicator {
  position: fixed;
  left: 0;
  z-index: 100;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  top: calc(var(--noti-height)  + var(--app-banner-height, 0px));
  left: 0;
  bottom: 0;
  right: 0;
  height: 10px;
  transition: top 0.2s ease-in-out;
}

.scrollBarContainer {
  width: 100%;
  height: 10px;
}

.scrollBar {
  height: 10px;
  width: 0%;
  transition: width 0.3s ease, background 0.3s ease;
}
