@import "./styles/_styles.scss";
.pageNotFound {
  padding: 70px 0 250px 0;
  margin: 0 auto;
  width: fit-content;
}
.image {
  &Container {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 96px;
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  &First {
    margin-right: -10px;
    z-index: 0;
  }
  &Second {
    z-index: 1;
  }
  &Thirst {
    margin-left: -15px;
    z-index: 2;
  }
}
.info {
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 68px;
  max-width: 620px;
  display: block;
}
.buttonBack {
  margin: 0 auto;
  display: block;
  width: fit-content;
}
@media screen and(max-width:768px) {
  .pageNotFound {
    padding: 72px 0 128px 0;
  }
  .image {
    &Container {
      margin-bottom: 48px;
    }
    &First {
      margin-right: -6px !important;
      svg {
        width: 81px;
        height: 106px;
      }
    }
    &Second {
      svg {
        width: 113px;
        height: 113px;
      }
    }
    &Thirst {
      margin-left: -8px !important;
      svg {
        width: 81px;
        height: 106px;
      }
    }
  }
  .info {
    padding: 0 32px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }
}
