@import "./styles/_styles.scss";
.moneySiteNotificationContainer {
  height: fit-content;
  transition: height 0.3s ease-in-out;
  width: 100%;
  .notificationBox {
    align-items: center;
    display: flex;
    height: 0;
    justify-content: left;
    left: 0;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: fixed;
    top: var(--app-banner-height, 0px);
    transition: all 0.3s ease-in-out;
    width: 100%;
    z-index: 100;

    &.warning {
      background-color: #ffed57;
    }

    .notificationMain {
      display: grid;
      grid-template-columns: 1fr 30px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1440px;
      padding: 0 60px;
      position: relative;
      width: 100%;
      @media screen and(max-width:768px) {
        padding: 0 25px;
      }
      @media screen and(max-width:576px) {
        padding: 0 15px;
      }

      .messageContent {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
      }

      .closeButton {
        cursor: pointer;
        display: flex;
        margin: auto;
        margin-right: unset;
        .closeIcon {
          height: 14px;
          position: relative;
          width: 14px;
          &::before,
          &::after {
            background: black;
            border-radius: 1.5px;
            bottom: -0.5px;
            content: '';
            height: 17px;
            position: absolute;
            right: 5.5px;
            transform-origin: center center;
            width: 3px;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &.haveContent {
    .notificationBox {
      height: fit-content;
      min-height: 56px;
      opacity: 1;
      padding: 16px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .notificationBox {
      .messageContent {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
