@import "./styles/_styles.scss";
@import '../../styles/color.scss';

$afterpay-btn-prefix: afterpay-money-btn;
$btn-height-base: 22;

.#{$afterpay-btn-prefix} {
    align-items: center;
    background: #fff;
    background-image: none;
    border: 0px solid transparent;
    border-color: $black;
    border-color: #d9d9d9;
    border-radius: 56px;
    box-shadow: 0 2px #00000004;
    color: $bondi-mint;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    display: inline-flex;
    font-size: 14px;
    font-weight: 400;
    font-weight: 700;
    height: 54px;
    justify-self: center;
    line-height: #{$btn-height-base}px;
    overflow: hidden;
    padding: 0 65px;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;

    &:disabled {
        background: $gray-80;
        border-color: transparent;
        box-shadow: none;
        color: $gray-40;
        text-shadow: none;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    span + .amicon,
    .amicon + span {
        margin-left: 10px;
    }

    .amicon {
        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }

    // Hover effect
    .before,
    .after {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        line-height: 54px;
        position: absolute;
        text-align: center;
        text-indent: 0;
        transition: all, 0.3s ease-out;
        width: 100%;
        span {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
        }
    }
    .none {
        opacity: 0;
    }
    .before {
        // position: relative;
        top: 100%;
    }
    .after {
        top: 0;
    }
    &:not(:disabled) {
        &:hover {
            .before {
                top: 0;
            }
            .after {
                top: -100%;
            }
        }
    }

    &-default {
        border-width: 1px;
        background: $white;
        border-color: #d9d9d9;
        color: $black;
    }
    &-black {
        background: $black;
        border-color: transparent;
        color: $bondi-mint;
    }
    &-mint {
        background: #b2fce4;
        border-color: transparent;
        color: $black;
    }
    &-olive {
        background: $olive-30;
        border-color: transparent;
        color: $black;
    }
    &-link {
        background: 0 0;
        border-color: transparent;
        border-radius: 0 !important;
        box-shadow: none;
        color: $black;
        &:hover {
            > * {
                text-decoration: underline;
            }
        }
    }
    &-round {
        border-radius: 32px;
        font-size: 14px;
        height: 32px;
        padding: 4px 16px;
        &.#{$afterpay-btn-prefix}-icon-only {
            border-radius: 2px;
            font-size: 16px;
            height: 32px;
            padding: 2.4px 0;
            vertical-align: -3px;
            width: 32px;
        }
    }

    &-circle {
        border-radius: 50%;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    &.#{$afterpay-btn-prefix}-icon-only {
        font-size: 18px;
        height: 38px;
        padding: 0;
        width: 38px;
        .before,
        .after {
            line-height: 38px;
        }

        &.#{$afterpay-btn-prefix} {
            &-ex-large {
                height: 75px;
                width: 75px;
                .before,
                .after {
                    line-height: 75px;
                }
                svg {
                    height: 30px;
                }
            }
            &-large {
                height: 60px;
                width: 60px;
                .before,
                .after {
                    line-height: 60px;
                }
                svg {
                    height: 25px;
                }
            }
            &-med {
                height: 32px;
                width: 32px;
                .before,
                .after {
                    line-height: 32px;
                }
                svg {
                    height: 16px;
                }
            }

            &-sm {
                height: 20px;
                width: 20px;
                .before,
                .after {
                    line-height: 20px;
                }
                svg {
                    max-height: 10px;
                    max-width: 10px;
                }
            }
        }
    }
}

.#{$afterpay-btn-prefix} {
    padding-top: 0.01px !important;
    &-ex-large {
        height: 60px;
        .before,
        .after {
            line-height: 60px;
        }
        svg {
            height: 25px;
        }
    }
    &-med {
        height: 42px;
        padding: 0 45px;
        .before,
        .after {
            line-height: 42px;
        }
        svg {
            height: 22px;
        }
    }

    &-sm {
        height: 34px;
        padding: 0 40px;
        .before,
        .after {
            line-height: 34px;
        }
        svg {
            height: 14px;
        }
    }
}
