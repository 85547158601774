@import "./styles/_styles.scss";
.moneySiteFooter {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 60px;
  background: #b2fce4;
  position: relative;
}
.copyright {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  p {
    margin-top: 0;
  }
  p:not(:last-of-type) {
    margin-bottom: 24px;
  }
  sup {
    font-size: 8px;
  }
  a {
    color: black;
  }
}
.footerTop {
  position: relative;
  margin-bottom: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-end;
  min-height: 157px;
}
.afterpayLogo {
  position: absolute;
  left: 0;
  height: 157px;
}
.gridTitles {
  display: grid;
  grid-template-columns: repeat(3, auto);
}
.gridColumn {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 50px;
  margin-top: 16px;
}
.gridItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
  a {
    color: #000;
    text-decoration: none;
    position: relative;
    &:hover {
      text-decoration: underline;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  cursor: pointer;
}
.iconAppleStore {
  width: 200px;
  margin-top: 16px;
  margin-bottom: 40px;
  svg {
    width: 200px;
    height: 60px;
  }
  cursor: pointer;
}
.iconsGrid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 27px;
  margin-bottom: 10px;
  justify-content: end;
  margin-top: auto;
  margin-bottom: auto;
  svg {
    cursor: pointer;
  }
}
.footerBar,
.backgroundFullScreen {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  height: 100%;
  z-index: -1;
  background-color: #b2fce4;
}
.footerBar {
  height: 20px;
  background-color: #000;
  z-index: 1;
}

.footerTopTablet {
  display: none;
}
.footerSmall {
  display: none;
}
.footerTopSmall {
  display: none;
}

@media screen and(max-width:1200px) {
  .afterpayLogo {
    width: fit-content;
    height: fit-content;
    svg {
      width: 281px;
      height: 133px;
    }
  }
  .iconAppleStore {
    margin-bottom: 28px;
  }
  .gridColumn {
    margin-right: 50px;
  }
}
@media screen and(max-width:1324px) {
  .footerTop {
    display: none;
  }
  .footerSmall {
    // display: block;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .moneySiteFooter {
    padding: 32px 32px 52px 32px;
  }

  .footerTopSmall {
    // width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    .afterpayLogo {
      position: inherit;

      svg {
        width: 206px;
        height: 102px;
        cursor: pointer;
      }
    }
    .iconGroup {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      flex-wrap: wrap;
    }
    .iconsGrid {
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .iconAppleStore {
      width: fit-content;
      height: fit-content;
      margin: 0;
      margin-left: 20px;
      svg {
        width: 182px;
        height: 55px;
      }
    }
  }
  .gridTitlesTablet {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 48px;
    column-gap: 87px;
    .gridColumn {
      margin: 0;
      margin-top: 16px;
    }
  }
}

@media screen and(max-width:768px) {
  .footerSmall {
    display: block;
    padding-bottom: 45px;
  }
}

@media screen and(max-width:576px) {
  .moneySiteFooter {
    padding: 32px 16px 52px 16px;
  }
  .gridTitlesTablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
    margin-bottom: 0;
  }

  .gridColumn {
    margin-bottom: 32px !important;
    margin-top: 0 !important;
  }
  // .gridColumn:last-of-type {
  //   grid-column: span 2;
  // }

  .afterpayLogo {
    svg {
      width: 147px !important;
      height: 70px !important;
    }
  }
  .iconAppleStore {
    svg {
      width: 166px !important;
      height: 50px !important;
    }
  }

  .iconsGrid {
    width: fit-content;
    position: absolute;
    margin-left: 16px;
    margin-right: auto;
    bottom: 0;
    left: 0;
    right: 0;
    svg {
      width: 30px !important;
      height: 30px !important;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    gap: 62px;
    margin-bottom: 32px;
  }
}
