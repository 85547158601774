@import "./styles/_styles.scss";
.menuNavigationContainer {
  position: relative;
}
.menuNavigation {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  list-style: none;
  position: absolute;
  top: 18px;
  left: 96px;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.hide {
    visibility: hidden;
    width: 0;
    height: 0;
    opacity: 0;
  }
  &.show {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  a {
    text-decoration: none;
    color: white;
  }
}
.menuItem {
  white-space: nowrap;
  &:hover {
    a {
      text-decoration: underline;
      transition: text-decoration 0.3s ease;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 21px;
  }
}
.homeTitle a {
  color: #b2fce4;
}
.iconMenuContainer {
  z-index: 2;

  svg {
    cursor: pointer;
    z-index: 3;
    position: absolute;
  }
}

.circleBackground {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.3s ease-out;
  &.show {
    transform: scale(17);
    background-color: #000;
  }
}
.menuDrawer {
  display: none;
}
@media screen and(max-width:768px) {
  .circleBackground {
    width: 48px;
    height: 48px;
  }
  .iconMenuContainer {
    svg {
      width: 48px;
      height: 48px;
    }
  }
}
@media screen and(max-width:576px) {
  .menuNavigationContainer {
    position: initial;
  }
  .iconMenuContainer {
    width: 59px;
    height: 59px;
    svg {
      width: 59px;
      height: 59px;
    }
    &.small {
      width: 48px !important;
      height: 48px !important;
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  .iconMenuDesktop {
    display: none;
  }
  .circleWrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
    max-width: 100vw;
    max-height: 100vh;
    animation: hide-menu 0.3s ease;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    &.show {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      width: 100vw;
      height: 100vh;
    }
  }
  .circleBackground {
    &.show {
      transform: scale(50);
    }
  }
  .menuContainer {
    .menuNavigation {
      display: none;
    }
  }

  //=====drawer=====//

  .menuDrawer {
    padding-bottom: 60px;
    overflow: scroll;
    &.show {
      height: calc(100vh - 102px);
      width: 100vw;
      display: block;
      position: absolute;
      top: 102px;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .menuNavigation {
      position: unset;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      padding-top: 52px;
      padding-left: 30px;
    }
    &.small.show {
      .menuNavigation {
        padding-top: 0;
        padding-left: 15px;
      }
      .btnGetApp{
        transform: translateX(-15px);
      }
    }
  }
}
.btnGetApp {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  a {
    color: #000;
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
  }
  background: #b2fce4;
  padding: 16px;
  border-radius: 56px;
  text-align: center;
  width: 319px;
  max-width: 100%;
  margin: 0 auto;
  
  margin-top: 145px;
  left: 30px;
}

@keyframes hide-menu {
  0% {
    overflow: visible;
  }

  10% {
    overflow: hidden;
  }
}
