@import "./styles/_styles.scss";
.moneySiteNavigation {
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 1440px;
}
.moneyNavigationContainer {
  width: 100%;
  max-width: 1440px;

  align-items: center;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 50%;

  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 2;
  &.large {
    padding: calc(75px + var(--noti-height) + var(--app-banner-height, 0px)) 60px 65px 60px;
    transition: padding-top 0.2s ease;
  }

  &.small {
    border-radius: 10px;
    padding: 26px 35px;
    position: fixed;
    top: calc(30px + var(--noti-height) + var(--app-banner-height, 0px));
    transition: top 0.2s ease-out;
    z-index: 2000;
  }
}
.moneyLogo {
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  &.small {
    svg {
      height: 86px;
      width: 86px;
    }
    svg,
    path {
      fill: #b2fce4;
      stroke: #b2fce4;
    }
  }
}
@media screen and(max-width:1440px) {
  .moneyNavigationContainer {
    &.small {
      left: 20px;
      margin-top: 30px;
      right: 20px;
      transform: none;
      width: auto;
    }
  }
}
@media screen and(max-width:768px) {
  .moneyNavigationContainer {
    &.large {
      padding: calc(44.5px + var(--noti-height) + var(--app-banner-height, 0px)) 24px 24.5px 24px;
      &.expand {
        padding: calc(44.5px + var(--app-banner-height, 0px)) 24px 24.5px 24px;
        z-index: 999;
      }
    }
    &.small {
      margin-top: 0;
      padding: 15px 16px;
      top: calc(20px + var(--noti-height) + var(--app-banner-height, 0px));
      &.expand {
        top: calc(20px + var(--app-banner-height, 0px));
        z-index: 999;
      }
    }
  }

  .moneyLogo {
    svg {
      height: 98px;
      width: 102px;
    }
    &.small {
      svg {
        height: 59px;
        width: 62px;
      }
    }
  }
}
@media screen and(max-width:576px) {
  .moneyNavigationContainer {
    flex-direction: row-reverse;
    &.large {
      padding: calc(32px + var(--noti-height) + var(--app-banner-height, 0px)) 16px 0 16px;
      &.expand {
        padding: 32px 16px 0 16px;
      }
    }
    &.small {
      padding: 4px 16px;
      transition: none;
      transition: margin 0.2s ease;
      &.expand {
        left: 20px !important;
        right: 20px !important;
        transition: top 0.2s ease;
      }
    }
  }
  .moneyLogo {
    height: 70px;
    position: unset;
    transform: none;
    width: 72px;
    z-index: 1;
    svg {
      height: 70px;
      width: 72px;
    }
    &.small {
      height: 40px;
      width: 40px;
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
      svg {
        height: 40px;
        width: 40px;
      }
    }
    &.small.filled {
      transition: transform 0.2s ease-in-out;
      transform: scale(1.75);
    }
    &.filled {
      svg,
      path {
        fill: #b2fce4;
        stroke: #b2fce4;
      }
    }
  }

  .btnGetApp {
    display: none;
  }
}

@keyframes small-menu-expand {
  0% {
    margin-top: 6px;
    padding: 6px 16px;
  }

  100% {
    left: 16px;
    margin-top: 18px;
    padding: 0 0 0 0;
    right: 16px;
  }
}
