@import "./styles/_styles.scss";
.blockNav {
  width: 100%;
  height: 200px;
  @media screen and(max-width:1200px) {
    height: 189px;
  }
  @media screen and(max-width:768px) {
    height: 117px;
  }
  @media screen and(max-width:576px) {
    height: 102px;
  }
}
